import SkeletonPlaceholder from '../../../../UI/SkeletonPlaceholder/SkeletonPlaceholder';
import style from './HomeNewsList.module.scss'

const HomeNewsSkeleton: React.FC = () => {
  if (window.matchMedia('(max-width: 620px)').matches) return (
    <SkeletonPlaceholder className={style.sliderPlaceholder} />
  )
  return (
    <div className={style.grid}>
      {[...Array(6)].map((_, index) => <SkeletonPlaceholder key={index} className={style.itemPlaceholder} />)}
    </div>
  )
}

export default HomeNewsSkeleton;
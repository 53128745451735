import { Routes, Route } from 'react-router-dom';
import Thanks from './components/pages/Thanks/Thanks';
import Home from './components/pages/Home/Home';
import NotFound from './components/pages/NotFound/NotFound';
import Layout from './components/Layout/Layout';
import About from './components/pages/About/About';
import './_generalStyles.scss'
import Contacts from './components/pages/Contacts/Contacts';
import Feedback from './components/pages/Feedback/Feedback';
import Job from './components/pages/Job/Job';
import LawyerQuestion from './components/pages/LawyerQuestion/LawyerQuestion';
import News from './components/pages/News/News';
import Faq from './components/pages/Faq/Faq';
import NewsItemPage from './components/pages/NewsItemPage/NewsItemPage';
import ThanksItem from './components/pages/ThanksItem/ThanksItem';
import Resume from './components/pages/Resume/Resume';
import FeedbackItem from './components/pages/SingleFeedback/SingleFeedback';
import ReviewItem from './components/pages/SingleReview/SingleReview';
import FaqItem from './components/pages/FaqItem/FaqItem';
import ScrollToTopProvider from './utils/ScrollToTop/ScrollToTop';
import JobItem from './components/pages/JobItem/JobItem';

const App: React.FC = () => {
  return <ScrollToTopProvider>
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />
        <Route path="/question-to-lawyer" element={<LawyerQuestion />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/faq/:id" element={<FaqItem />} />
        <Route path="/about" element={<About />} />
        <Route path="/job" element={<Job />} />
        <Route path='/job/:id' element={<JobItem />} />
        <Route path='/job/resume' element={<Resume />} />
        <Route path="/news" element={<News />} />
        <Route path="/news/view/:id" element={<NewsItemPage />} />
        <Route path="/feedback" element={<Feedback />} />
        <Route path="/feedback/:id" element={<FeedbackItem />} />
        <Route path="/review/:id" element={<ReviewItem />} />
        <Route path="/thanks" element={<Thanks />} />
        <Route path='/thanks/view/:id' element={<ThanksItem />} />
        <Route path="/contacts" element={<Contacts />} />
        <Route path='*' element={<NotFound />} />
      </Route>
    </Routes>
  </ScrollToTopProvider>
}

export default App;

import { Link } from 'react-router-dom';
import style from './LawyerQuestionButton.module.scss'
import Button, { ButtonTheme } from '../../UI/Button/Button';

const LawyerQuestionButton: React.FC = () => {
  return (
    <Link to="/question-to-lawyer">
      <Button theme={ButtonTheme.RED} className={style.button}>Вопрос юристу</Button>
    </Link>
  )
}

export default LawyerQuestionButton;
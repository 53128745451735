import { useEffect, useState } from 'react'
import style from './UpButton.module.scss'
import { scrollToTop } from '../../../utils/ScrollToTop/ScrollToTop'
import Button from '../../UI/Button/Button'
import { classNames } from '../../../utils/classNames/classNames'

const root = document.getElementById('root') as HTMLElement

const UpButton: React.FC = () => {
  const [scrollPos, setScrollPos] = useState(0)

  const handleScroll = () => {
    setScrollPos(root?.scrollTop)
  }

  useEffect(() => {
    root?.addEventListener('scroll', handleScroll)

    return () => root?.removeEventListener('scroll', handleScroll)
  }, [])

  return (
    <Button
      className={classNames(style.button, { [style.shown]: scrollPos > 699 })}
      onClick={scrollToTop}>
      НАВЕРХ ▲
    </Button>
  )
}

export default UpButton;
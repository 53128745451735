import SkeletonPlaceholder from '../../UI/SkeletonPlaceholder/SkeletonPlaceholder';
import style from './Faq.module.scss'

const FaqSkeleton: React.FC = () => {
  return (
    <div className={style.placeholderWrapper}>
      {
        [...Array(10)].map((_, index) => {
          return (
            <SkeletonPlaceholder key={index} className={style.itemPlaceholder} />
          )
        })
      }
    </div>
  )
}

export default FaqSkeleton;
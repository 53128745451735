import {IFeedbackForm, ILawyerQuestion, IResumeForm} from "../types/formInterfaces";
import createSearchParamsString from "./createSearchParamsString/createSearchParamsString";

export const BASE_URL = 'https://chg-mall.ru/';

export const sendFormEmail = async (data: string, token: string) => {
  let formData = new FormData();
  formData.append('email', String(data))
  formData.set('reCaptcha', token);
  const response = await fetch(`${BASE_URL}api/reception/sub/create`, {
    body: formData,
    method: 'post'
  })

  return response;
}

export const sendFormResume = async (data: IResumeForm, token: string) => {
  let formData = new FormData();
  for (let item in data) {
    formData.append(item, String(data[item as keyof IResumeForm]))
  }
  formData.set('reCaptcha', token);
  const response = await fetch(`${BASE_URL}api/reception/resume/create`, {
    body: formData,
    method: 'post'
  })

  return response;
}

export const sendFormFeedback = async (data: IFeedbackForm, token: string) => {
  let formData = new FormData();
  for (let item in data) {
    formData.append(item, String(data[item as keyof IFeedbackForm]))
  }
  formData.set('reCaptcha', token);
  const response = await fetch(`${BASE_URL}api/reception/review-form/create`, {
    body: formData,
    method: 'post'
  })

  return response;
}

export const sendFormLawyerQuestion = async (data: ILawyerQuestion, token: string, files: File[]) => {
  let formData = new FormData();
  for (let item in data) {
    if (item === 'gosuslugi') {
      formData.append(item, String(Number(data[item])));
    } else {
      formData.append(item, String(data[item as keyof ILawyerQuestion]));
    }
  }
  ;
  files.forEach((item, index) => {
    formData.append(`ReceptionQuestion[file_id][${index}]`, item)
  })
  formData.set('reCaptcha', token);
  const response = await fetch(`${BASE_URL}api/reception/question/create`, {
    body: formData,
    method: 'post'
  })

  return response;
}

interface getDataSettings {
  models?: boolean,
  searchParams?: Record<string, string | string[] | undefined | null>,
}

export const getData = async <DataType extends {}>(
  path: string,
  settings?: getDataSettings
): Promise<DataType> => {

  const models = settings?.models ?? true;

  const data = await fetch(
    `${BASE_URL}api/reception/${path}?${createSearchParamsString(settings?.searchParams)}`
  )
    .then(res => {
      if (res.ok) {
        return res.json()
      }
      throw new Error('connection failed', {cause: {status: res.status}})
    });

  return models ? data?.models : data;
}

import { useEffect } from 'react'
import style from './NotFound.module.scss'
import { Link } from 'react-router-dom'
import { setDocumentTitle } from '../../../utils/setDocumentTitle/setDocumentTitle'

const NotFound: React.FC = () => {

  useEffect(() => {
    setDocumentTitle('Страница не найдена')
  }, [])

  return (
    <main className={style.wrapper}>
      <h1>Страница не найдена</h1>
      <p>Запрашиваемая страница не существует</p>
      <p>Возможно, она была удалена или в запросе был указан неверный адрес страницы</p>
      <p>Попробуйте вернуться <Link to="/"><strong>на главную</strong></Link> или связаться с нами по телефону <a href='tel:+74732619999'><strong>+7(473)261-99-99</strong></a></p>
    </main>
  )
}

export default NotFound;
import { memo } from 'react';
import { Link } from 'react-router-dom';
import style from './BreadCrumbs.module.scss'

export interface IBreadCrumb {
  name: string,
  link: string
}

const BreadCrumbs: React.FC<{ links: IBreadCrumb[] }> = ({ links }) => {
  const lastElement: number = links.length - 1;

  return (
    <ul className={style.breadCrumbs}>
      {
        links.map((link, index) => {
          return <li key={index} className={style.item}>
            {index === lastElement ?
              <span>{link.name}</span> :
              <Link className={style.itemLink} to={link.link}>
                {link.name}
              </Link>}
          </li>
        })}
    </ul>
  )
}

export default memo(BreadCrumbs);
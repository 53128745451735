import { Link, useParams } from 'react-router-dom'
import { useEffect } from 'react'
import style from './JobItem.module.scss'
import BreadCrumbs from '../../share/BreadCrumbs/BreadCrumbs';
import Container from '../../UI/Container/Container';
import Title from '../../UI/Title/Title';
import { setDocumentTitle } from '../../../utils/setDocumentTitle/setDocumentTitle';
import { useQuery } from 'react-query';
import { getData } from '../../../utils/services';
import { IReceptionJobs } from '../../../types/apiInterfaces';
import SinglePageSkeleton from '../../share/SinglePageSkeleton/SinglePageSkeleton';
import Button from '../../UI/Button/Button';

const JobItem: React.FC = () => {
  const slug = useParams().id;

  const { data, isLoading } = useQuery({
    queryKey: ['job-item', slug],
    queryFn: (): Promise<IReceptionJobs> => getData(`job/get-job/${slug}`)
  })

  useEffect(() => {
    if (data?.title) {
      setDocumentTitle(data.title)
    }
  }, [data])

  return (
    <Container>
      <BreadCrumbs links={[{ name: 'Главная', link: '/' },
      { name: 'Вакансии', link: '/job' }, { name: '', link: '' }]} />
      {isLoading ? <SinglePageSkeleton /> :
        <div className={style.wrapper}>
          <Title>{data?.title}</Title>
          <div className={style.description}
            dangerouslySetInnerHTML={{ __html: data?.description ?? '' }}>
          </div>
          <Link to='/job/resume' className={style.link}>
            <Button className={style.linkButton}>Откликнуться</Button>
          </Link>
        </div>
      }
    </Container>
  )
}

export default JobItem;
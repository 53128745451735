import { classNames } from '../../../utils/classNames/classNames';
import style from './Container.module.scss'

interface ContainerProps {
  className?: string,
  children: React.ReactNode
}

const Container: React.FC<ContainerProps> = (props) => {
  const { children, className } = props;

  return <div className={classNames(style.container, {}, [className ?? ''])}>
    {children}
  </div>
}

export default Container;
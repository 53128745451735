import style from './ReviewSlider.module.scss'
import { useState, useRef } from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Navigation, Autoplay, Pagination } from "swiper";
import { Link } from 'react-router-dom';
import { IMedia } from '../../../types/apiInterfaces';
import { isArrayFull } from '../../../utils/isArrayFull/isArrayFull';
import { useQuery } from 'react-query';
import { getData } from '../../../utils/services';
import ReviewSliderSkeleton from './ReviewSliderSkeleton/ReviewSliderSkeleton';

interface ReviewSliderProps {
  type: 1 | 2,
}

const ReviewSlider: React.FC<ReviewSliderProps> = ({ type }) => {
  const [_, setSwiper] = useState<boolean>(false);
  const navPrevRef = useRef<HTMLButtonElement | null>(null)
  const navNextRef = useRef<HTMLButtonElement | null>(null)

  const { data, isLoading } = useQuery({
    queryKey: ['media-slider', type],
    queryFn: (): Promise<IMedia[][]> => getData('media-about/get-by-slider', { searchParams: { count: String(type) } })
  })

  if (isLoading) return <ReviewSliderSkeleton type={type} />

  if (!isArrayFull(data)) return null

  return (
    <div>
      <Swiper
        navigation={{
          prevEl: navPrevRef?.current,
          nextEl: navNextRef?.current,
        }}
        pagination={{
          clickable: true,
          bulletClass: type === 1 ? style.customBullets_1 : style.customBullets_2,
          bulletActiveClass: type === 1 ? style.activeCustomBullets_1 : style.activeCustomBullets_2,
          renderBullet: (_, className) => {
            return `<button class="${className}" style="margin-left: 20px"></button>`;
          }
        }}
        slidesPerView={1}
        loop={true}
        speed={700}
        breakpoints={{
          920: {
            slidesPerView: 3
          },
          620: {
            slidesPerView: 2
          }
        }}
        autoplay={{
          delay: 30000,
          disableOnInteraction: false,
        }}
        onSwiper={() => setSwiper(true)}
        modules={[Autoplay, Navigation, Pagination]}
        className={type === 1 ? style.slider_1 : style.slider_2}
      >
        {data?.map((arr, index) => {
          if (type === 1) {
            return arr.map((item, index) => {
              return <SwiperSlide key={index} className={style.slide}>
                <iframe src={item.url}
                  title={item.title}
                  frameBorder={0}
                  width="100%"
                  allow="clipboard-write; accelerometer; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen />
                <div className={style.iframeText}>
                  <Link to={`/review/${item.slug}`}>
                    {item.title}
                  </Link>
                </div>
              </SwiperSlide>
            })
          }
          else {
            const elements = arr.map((item, index) => {
              return <div key={index}>
                <iframe src={item.url}
                  title={item.title}
                  frameBorder={0}
                  width="100%"
                  allow="clipboard-write; accelerometer; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen />
                <div className={style.iframeText}>
                  <Link to={`/review/${item.slug}`}>
                    {item.title}
                  </Link>
                </div>
              </div>
            })
            return <SwiperSlide key={index} className={style.slide}>
              <div className={style.slidesBlock}>
                {elements}
              </div>
            </SwiperSlide>
          }
        })}
      </Swiper>

      <button
        className={style.navigationPrev}
        ref={navPrevRef}
        aria-label='Предыдущий слайд'
      />
      <button
        className={style.navigationNext}
        ref={navNextRef}
        aria-label='Следующий слайд'
      />
    </div>
  )
}

export default ReviewSlider;
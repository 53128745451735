import { ReactNode } from 'react';
import style from './CheckBox.module.scss'
import { classNames } from '../../../utils/classNames/classNames';

interface CheckBoxProps {
  className?: string
  id: string
  children?: ReactNode,
  onChange: () => void,
  checked: boolean
}

const CheckBox: React.FC<CheckBoxProps> = (props) => {
  const {
    className = '',
    id,
    children,
    onChange,
    checked
  } = props;

  return (
    <div className={classNames(style.checkbox, {}, [className])}>
      <label htmlFor={id}>
        <input
          id={id}
          type="checkbox"
          className={style.input}
          onChange={onChange}
          checked={checked}
        />
        <span className={style.text}>{children}</span>
        <span className={style.switcher}></span>
      </label>
    </div>
  )
}

export default CheckBox;
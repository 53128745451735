import style from './HomeNewsList.module.scss'
import { formatPassedTime } from '../../../../../utils/formatPassedTime/formatPassedTime';
import NewsItem from '../../../../share/NewsItem/NewsItem';
import { Swiper, SwiperSlide } from "swiper/react"
import { Autoplay, Pagination } from "swiper";
import { INews } from '../../../../../types/apiInterfaces';

interface HomeNewsListProps {
  news?: INews[]
}

const HomeNewsList: React.FC<HomeNewsListProps> = ({ news }) => {
  if (window.matchMedia('(max-width: 620px)').matches) return (
    <Swiper
      className={style.newsSlider}
      spaceBetween={15}
      pagination={{
        clickable: true,
        bulletClass: style.customBullets,
        bulletActiveClass: style.activeCustomBullets,
        renderBullet: (_, className) => {
          return `<button class="${className}" style="margin-left: 20px"></button>`;
        }
      }}
      modules={[Autoplay, Pagination]}
    >
      {news?.slice(0, 5).map(item => <SwiperSlide key={item.created_at}>
        <NewsItem
          image={item.imagePath}
          slug={item.slug}
          title={item.title}
          date={formatPassedTime(item.created_at, item.timeDifference)} />
      </SwiperSlide>)}
    </Swiper>
  )

  return (
    <div className={style.grid}>
      {news?.slice(0, 6).map(item => {
        return <NewsItem
          key={item.created_at}
          image={item.imagePath}
          slug={item.slug}
          title={item.title}
          date={formatPassedTime(item.created_at, item.timeDifference)}
        />
      })}
    </div>
  )
}

export default HomeNewsList;
import { BASE_URL } from '../../../../utils/services';
import { IGalleryItem } from '../../../../types/apiInterfaces';
import style from './ThanksList.module.scss'

interface ThanksListProps {
  items: IGalleryItem[],
  openGallery: (index: number) => void
}

const ThanksList: React.FC<ThanksListProps> = ({ items, openGallery }) => {
  return <>
    <div className={style.thanksWrapper}>
      {items.map(({ path }, index) => <div key={index} className={style.thank}>
        <img src={`${BASE_URL}${path}`}
          width="100%"
          alt='Грамота'
          loading='lazy'
          className={style.thankImage}
          onClick={() => {
            openGallery(index);
          }} />
      </div>)}
    </div>
  </>
}

export default ThanksList;
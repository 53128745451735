import { Link } from 'react-router-dom'
import style from './FeedbackSliderItem.module.scss'

interface SliderItemProps {
  name: string
  title: string
  text: string
  date: string
  slug: string
}

const FeedbackSliderItem: React.FC<SliderItemProps> = (props) => {
  const { name, title, date, text, slug } = props;

  return (
    <div className={style.wrapper}>
      <h3 className={style.title}>
        <p>{name}</p>
        <p>{title}</p>
      </h3>
      <div className={style.itemDescription} dangerouslySetInnerHTML={{ __html: text }} />
      <Link to={`/feedback/${slug}`} className={style.fullText}>
        Читать полностью
      </Link>
      <p className={style.itemDate}>{date}</p>
    </div>
  )
}

export default FeedbackSliderItem;
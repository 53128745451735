import style from './HomeNewsBlock.module.scss'
import HomeNewsList from './HomeNewsList/HomeNewsList';
import { useQuery } from 'react-query';
import { INews } from '../../../../types/apiInterfaces';
import { getData } from '../../../../utils/services';
import HomeNewsSkeleton from './HomeNewsList/HomeNewsSkeleton';
import { Link } from 'react-router-dom';
import Button from '../../../UI/Button/Button';

const HomeNewsBlock: React.FC = () => {

  const { data, isLoading } = useQuery({
    queryKey: ['news', '2023', 1],
    queryFn: (): Promise<INews[]> => getData('news')
  })

  return (
    <section className={style.newsBackground}>
      <div className={style.news}>
        <h3 className={style.title}>Последние новости</h3>
        {isLoading ? <HomeNewsSkeleton /> : <HomeNewsList news={data} />}
        <Link to="/news" className={style.linkWrapper}>
          <Button className={style.newsButton}>
            Больше новостей
          </Button>
        </Link>
      </div>
    </section>
  )
}

export default HomeNewsBlock;
interface IMapData {
  geometry: number[],
  title: string,
  text: string,
  id: number
}

export const ContactsMapData: IMapData[] = [
  { id: 1, geometry: [51.668289, 39.207653], title: 'Управление общественных приемных (г. Воронеж)', text: '<p>тел. (473) 255-05-74</p><p>руководитель Управления</p><p>Христенко Яна Юрьевна</p><a href="mailto:opsvch@mail.ru">opsvch@mail.ru</a>'},
  { id: 2, geometry: [51.634186, 39.187299], title: 'Ленинский район г. Воронеж, ул. Грамши, 70', text: '<p>время работы: с 9.00 до 18.00,</p><p>перерыв с 13.00 до 14.00</p><p>тел. (473) 236-26-43</p><p>Руководитель</p><p>Общественной приемной</p><p>Перцева Нина Ивановна</p>' },
  { id: 3, geometry: [51.654147, 39.188419], title: 'Ленинский район г. Воронеж, ул. 20-летия Октября, 115', text: '<p>здание управы Ленинского района</p><p>городского округа город Воронеж</p><p>время работы: с 9.00 до 18.00,</p><p>перерыв с 13.00 до 14.00</p><p>тел. (473) 278-21-09</p>' },
  { id: 4, geometry: [51.649208, 39.151805], title: 'Советский район г. Воронеж, ул. Домостроителей, 30', text: '<p>каб. 119 а, здание управы</p><p>Советского района городского</p><p>округа город Воронеж</p><p>время работы: с 9.00 до 18.00,</p><p>перерыв с 13.00 до 14.00</p><p>тел. (473) 278-69-36</p>' },
  { id: 5, geometry: [50.863812, 39.080335], title: 'Острогожский район Воронежская обл., г. Острогожск, ул. К. Маркса, 18А', text: '<p>время работы: с 9.00 до 17.00</p><p>тел. (473-75) 4-36-50</p>' },
  { id: 6, geometry: [51.075800, 38.640619], title: 'Репьевский район Воронежская обл., с. Репьевка, пл. Победы, 1', text: '<p>время работы: с 9.00 до 17.00</p><p>тел. (473-74) 2-26-83</p>' },
  { id: 7, geometry: [51.546317, 38.363893], title: 'Нижнедевицкий район Воронежская обл., с. Нижнедевицк, ул. Братьев Серых, 6', text: '<p>время работы: с 9.00 до 17.00</p><p>тел. (473-70) 5-14-34</p>' },
  { id: 11, geometry: [51.310131, 39.214993], title: 'Городской округ город Нововоронеж Воронежская обл., г. Нововоронеж, ул. Мира, 21', text: '<p>время работы: с 9.00 до 18.00</p><p>перерыв с 13.00 до 14.00</p><p>тел. (473-64) 2-55-14, 5-39-52</p>' },
  { id: 8, geometry: [46.836522, 29.628513], title: 'Приднестровская Молдавская Республика г. Тирасполь, ул. 25 Октября, д. 118А', text: '<p>график работы:</p><p>пн - ср с 8.00 до 17.00,</p><p>перерыв с 13.00 до 14.00;</p><p>чт - сб с 15.00 до 19.00</p><p>без перерыва</p><p>тел. (10-373) 533-680-39</p>' },
  { id: 9, geometry: [51.684083, 39.033533], title: 'Семилукский район Воронежская обл., г. Семилуки, ул. Ленина, 11, каб. 19', text: '<p>время работы: понедельник, вторник, среда, четверг с 9.00 до 17.00</p><p>тел. (473-72)2-70-06</p>' },
  { id: 10, geometry: [51.578288, 38.785903], title: 'Хохольский район Воронежская обл., р.п. Хохольский, ул. Ленина, 8', text: '<p>время работы: пятница, с 9.00 до 17.00</p><p>тел. (473-71) 4-30-92</p>' },
]
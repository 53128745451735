import SkeletonPlaceholder from '../../UI/SkeletonPlaceholder/SkeletonPlaceholder';
import style from './News.module.scss'

const NewsSkeleton: React.FC = () => {
  return <div className={style.news}>
    {[...Array(3)].map(() => {
      return <SkeletonPlaceholder className={style.itemPlaceholder} />
    })}
  </div>
}

export default NewsSkeleton;
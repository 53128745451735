import { Link } from 'react-router-dom';
import style from './FormSuccess.module.scss'
import { classNames } from '../../../utils/classNames/classNames';
import Button from '../../UI/Button/Button';
import Portal from '../Portal/Portal';

interface IFormSucces {
  title: string,
  description: string,
  isSent: boolean
}

const FormSuccess: React.FC<IFormSucces> = ({ title, description, isSent }) => {

  return (
    <Portal>
      <div className={classNames(style.overlay, { [style.active]: isSent })}>
        <div className={style.content}>
          <div className={style.iconWrapper}>
            <i className={style.icon} />
          </div>
          <h4 className={style.title}>{title}</h4>
          <div className={style.description} dangerouslySetInnerHTML={{ __html: description }} />
          <Link to="/">
            <Button>Вернуться в приемную</Button>
          </Link>
        </div>
      </div>
    </Portal>
  )
}

export default FormSuccess;
import style from './About.module.scss'
import { Link } from 'react-router-dom';
import img18 from '../../../assets/images/about/18.png'
import img90 from '../../../assets/images/about/90.png'
import duma from '../../../assets/images/about/duma.png'
import free from '../../../assets/images/about/free.png'
import info from '../../../assets/images/about/info.png'
import mode from '../../../assets/images/about/more.png'
import FeedbackSlider from '../../share/FeedBackSlider/FeedbackSlider';
import BreadCrumbs, { IBreadCrumb } from '../../share/BreadCrumbs/BreadCrumbs';
import Container from '../../UI/Container/Container';
import { useEffect } from 'react';
import { setDocumentTitle } from '../../../utils/setDocumentTitle/setDocumentTitle';
import { useInView } from 'react-intersection-observer';
import FeedbackSliderSkeleton from '../../share/FeedBackSlider/FeedbackSliderSkeleton/FeedbackSliderSkeleton';
import Button from '../../UI/Button/Button';

const About: React.FC = () => {
  const path: IBreadCrumb[] = [{ name: 'Главная', link: '/' },
  { name: 'О нас', link: '/about' }]

  useEffect(() => {
    setDocumentTitle('О нас')
  }, [])

  const { ref, inView } = useInView({
    rootMargin: '5px',
    triggerOnce: true
  })


  return (
    <Container>
      <BreadCrumbs links={path} />
      <div className={style.wrapper}>
        <h2>Наша миссия:</h2>
        <div className={style.mission}>
          <div className={style.content}>
            <p>оказать каждому</p>
            <p>бесплатную и качественную</p>
            <p>юридическую помощь</p>
          </div>
          <div className={style.statistic}>
            <p>373 112</p>
            <p>граждан получили помощь в ОП</p>
          </div>
          <div className={style.statistic}>
            <p>10 919</p>
            <p>обращений обработано за 2020 год</p>
          </div>
        </div>

        <h2>Преимущества:</h2>
        <div className={style.advantage}>
          <div className={style.advantageItem}>
            <img src={img18} alt="" />
            <p><strong>18</strong> лет практической работы <br /> по улучшению качества <br /> жизни в регионе</p>
          </div>
          <div className={style.advantageItem}>
            <img src={free} alt="" />
            <p><strong>Бесплатная</strong> и профессиональная <br /> юридическая помощь <br /> населению</p>
          </div>
          <div className={style.advantageItem}>
            <img src={mode} alt="" />
            <p><strong>Более 365 000</strong> человек <br /> которым оказана <br /> помощь</p>
          </div>
          <div className={style.advantageItem}>
            <img src={img90} alt="" />
            <p><strong>90% обращений</strong> решено <br /> положительно, по остальным — <br />работа продолжается</p>
          </div>
          <div className={style.advantageItem}>
            <img src={info} alt="" />
            <p><strong>Информирование</strong> граждан <br /> на каждом этапе решения <br /> обращения </p>
          </div>
          <div className={style.advantageItem}>
            <img src={duma} alt="" />
            <p><strong>Решение</strong> каждого вопроса — <br /> на личном контроле<br /> Сергея Чижова</p>
          </div>
        </div>

        <h2 ref={ref}>Результаты нашей работы</h2>
        <div>
          {
            inView ? <FeedbackSlider />
              : <FeedbackSliderSkeleton />
          }
          <div className={style.buttonWrapper}>
            <Link to="/feedback" className={style.button}>
              <Button>Оставить отзыв</Button>
            </Link>
          </div>
        </div>

        <h2>Работа у нас</h2>
        <div className={style.job}>
          <h3>Нам нужны профессионалы</h3>
          <p>В отдельном разделе мы ведем поиск новых сотрудников. Если вы опытный специалист и хотели бы работать в Ассоциации "Галерея Чижова", просмотрите данный раздел.</p>
          <Link to="/job">
            <Button>Посмотреть вакансии</Button>
          </Link>
        </div>
      </div>
    </Container>
  )
}

export default About;
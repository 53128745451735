import React, {useState, useCallback, useEffect} from 'react'
import { FormStatusType, ILawyerQuestion } from '../../../../types/formInterfaces'
import style from './LawyerQuestionForm.module.scss'
import { GoogleReCaptcha } from 'react-google-recaptcha-v3';
import { sendFormLawyerQuestion } from '../../../../utils/services';
import { useForm } from 'react-hook-form';
import Button, { ButtonTheme } from '../../../UI/Button/Button';
import FormSuccess from '../../../share/FormSuccess/FormSuccess';
import Input from '../../../UI/Input/Input';
import TextArea, { TextAreaSize } from '../../../UI/TextArea/TextArea';
import { classNames } from '../../../../utils/classNames/classNames';
import CheckBox from '../../../UI/CheckBox/CheckBox';
import ErrorText from '../../../UI/ErrorText/ErrorText';
import Loader from '../../../share/Loader/Loader';

const LawyerQuestionForm: React.FC = () => {
  const permittedFileTypes: string[] = ['image/jpg', 'image/png', 'application/pdf', 'image/jpeg']
  const [token, setToken] = useState<string>();
  const [refreshReCaptcha, setRefreshReCaptcha] = useState<boolean>(false);
  const [files, setFiles] = useState<File[] | null>(null);
  const [filesError, setFilesError] = useState<boolean | string>(false);
  const [status, setStatus] = useState<FormStatusType>(null);
  const [isSending, setIsSending] = useState(false);
  const [gosuslugi, setGosuslugi] = useState(false);
  const [agreement, setAgreement] = useState(false);
  const [checkBoxDirty, setCheckBoxDirty] = useState(false)

  const { register,
    handleSubmit,
    formState: { errors, touchedFields },
    reset,
  } = useForm<ILawyerQuestion>({ mode: 'onBlur' });

  const onVerify = useCallback((token: string) => {
    setToken(token);
  }, []);

  useEffect(() => {
    const id = setInterval(() => {
      setRefreshReCaptcha(r => !r);
    }, 120000)
    return () => clearInterval(id)
  }, [])

  const onFileChange = (filesLoad: FileList | null) => {
    filesLoad && setFiles(Array.from(filesLoad));
    let filesSize: number = filesLoad ?
      Array.from(filesLoad).reduce((acc, current) => acc + current.size, 0) : 0
    let typeError = false;
    filesLoad && Array.from(filesLoad).forEach(file => {
      if (permittedFileTypes.indexOf(file.type) === -1) {
        typeError = true;
      }
    })
    if (filesLoad!.length > 4) {
      setFilesError('Вы не можете загружать более 4 файлов.')
    }
    else if (filesSize > 20 * 1024 * 1024) {
      setFilesError('Размер загруженных файлов превышает 20 Мб.')
    }
    else if (typeError) {
      setFilesError('Возможный формат прикрепленных файлов: PDF, JPG, PNG.')
    }
    else setFilesError(false)
  }

  const onSubmit = async (data: ILawyerQuestion) => {
    if (filesError || !agreement) {
      setCheckBoxDirty(true)
      return
    }
    try {
      setIsSending(true)
      setStatus(null)
      const response = await sendFormLawyerQuestion({ ...data, gosuslugi }, token ?? '', files ?? []);
      if (!response.ok) {
        throw new Error();
      }
      setRefreshReCaptcha(r => !r);
      reset();
      setStatus('success');
    }
    catch (e) {
      setStatus('error')
    }
    finally {
      setIsSending(false)
    }
  }

  const checkIfBlockSuccess = (...args: (keyof ILawyerQuestion)[]): boolean => {
    let isSuccess = true;
    args.forEach(item => {
      if (!touchedFields[item] || errors[item]) {
        isSuccess = false;
      }
    })
    return isSuccess;
  }

  return (
    <>
      <FormSuccess
        isSent={status === 'success'}
        title="Вопрос отправлен"
        description='Ваш вопрос отправлен на обработку. <br/> Ожидайте ответ.'
      />
      <form onSubmit={handleSubmit(onSubmit)} className={style.form}>
        <div className={classNames(style.inputValidIndicator,
          {
            [style.hasError]: Boolean(errors.first_name) || Boolean(errors.last_name),
            [style.hasSuccess]: checkIfBlockSuccess('first_name', 'last_name')
          })}
        >
          <h3 className={style.step}>Шаг №1.</h3>
          <div className={style.formGroup}>
            <Input
              error={errors.last_name}
              touched={touchedFields.last_name}
              placeholder='Фамилия'
              {...register('last_name', {
                required: 'Необходимо заполнить «Фамилия».'
              })}
            />
            <Input
              error={errors.first_name}
              touched={touchedFields.first_name}
              placeholder='Имя'
              {...register('first_name', {
                required: 'Необходимо заполнить «Имя».'
              })}
            />
            <div>
              <Input
                error={errors.middle_name}
                touched={touchedFields.middle_name}
                placeholder='Отчество'
                {...register('middle_name', {
                  required: false
                })}
              />
              <p className={style.middleName}>Отчество при наличии в паспорте</p>
            </div>
          </div>
        </div>

        <div className={classNames(style.inputValidIndicator,
          {
            [style.hasError]: Boolean(errors.email) || Boolean(errors.phone),
            [style.hasSuccess]: checkIfBlockSuccess('email', 'phone')
          })}
        >
          <h3 className={style.step}>Шаг №2.</h3>
          <div className={style.formGroup}>
            <Input
              error={errors.phone}
              touched={touchedFields.phone}
              placeholder='Телефон'
              mask="+7(999)999-99-99"
              type="tel"
              {...register('phone', {
                required: 'Необходимо заполнить «Телефон».',
                pattern: {
                  value: /^[^_]*$/,
                  message: 'Необходимо заполнить «Телефон».'
                }
              })}
            />
            <Input
              error={errors.email}
              touched={touchedFields.email}
              placeholder='Почта'
              type="email"
              {...register('email', {
                required: 'Необходимо заполнить «Email».',
                pattern: {
                  value: /\S+@\S+\.\S+/,
                  message: 'Значение «E-mail» не является правильным email адресом.'
                }
              })}
            />
          </div>
        </div>

        <div className={classNames(style.inputValidIndicator,
          {
            [style.hasError]: Boolean(errors.address),
            [style.hasSuccess]: checkIfBlockSuccess('address')
          })}
        >
          <h3 className={style.step}>Шаг №3.</h3>
          <Input
            error={errors.address}
            touched={touchedFields.address}
            placeholder='Адрес проживания'
            {...register('address', {
              required: 'Необходимо заполнить «Адрес».',
              minLength: {
                value: 5,
                message: 'Значение «Адрес» должно содержать минимум 5 символов.'
              }
            })}
          />
        </div>

        <div className={classNames(style.inputValidIndicator,
          {
            [style.hasError]: Boolean(errors.text),
            [style.hasSuccess]: checkIfBlockSuccess('text')
          })}
        >
          <h3 className={style.step}>Шаг №4.</h3>
          <TextArea
            size={TextAreaSize.M}
            error={errors.text}
            touched={touchedFields.text}
            placeholder='Опишите Вашу проблему (максимум 1000 символов)'
            {...register('text', {
              required: 'Необходимо заполнить «Текст».',
              minLength: {
                value: 5,
                message: 'Значение «Текст» должно содержать минимум 5 символов.'
              },
              maxLength: {
                value: 1000,
                message: 'Значение «Текст» должно содержать максимум 1 000 символов'
              }
            })}
          />
        </div>

        <div className={classNames(style.inputValidIndicator,
          {
            [style.hasError]: Boolean(files?.length) && filesError,
            [style.hasSuccess]: Boolean(files?.length) && !filesError
          })}
        >
          <h3 className={style.step}>Шаг №5.</h3>
          <p className={style.addDocs}>Прикрепите необходимые документы</p>
          <label htmlFor={style.file} className={style.fileLabel}>
            Добавить файлы
          </label>
          <p className={style.helpBlock}>{filesError ? filesError : ''}</p>
          <input type="file" id={style.file} multiple
            accept=".png, .jpg, .pdf"
            onChange={(e) => {
              onFileChange(e.target.files)
            }}
          />
          {files?.length ? <p><strong>Выбрано:</strong></p> : null}
          {files?.map((item, index) => {
            return <p key={index}>{item.name}</p>
          })}
          <p style={{ marginTop: 20 }}>Возможный формат прикрепленных файлов: PDF, JPG, PNG.</p>
          <p>Максимальный размер файлов 20 Мб.</p>
        </div>

        <div
          className={classNames(style.inputValidIndicator, {
            [style.hasError]: checkBoxDirty && !agreement,
            [style.hasSuccess]: checkBoxDirty && agreement
          })}
        >
          <h3 className={style.step}>Шаг №6.</h3>
          <CheckBox
            className={style.checkbox}
            id='agreement'
            checked={agreement}
            onChange={() => {
              if (!checkBoxDirty) {
                setCheckBoxDirty(true)
              }
              setAgreement(pr => !pr)
            }}
          >
            Ознакомлен и даю согласие на обработку персональных данных.
          </CheckBox>
          <CheckBox
            className={style.checkbox}
            id='gosUslugi'
            checked={gosuslugi}
            onChange={() => {
              if (!checkBoxDirty) {
                setCheckBoxDirty(true)
              }
              setGosuslugi(pr => !pr)
            }}
          >
            Вы пользуетесь интернет - порталом «Госуслуги»?.
          </CheckBox>
        </div>

        <GoogleReCaptcha
          action='homepage'
          onVerify={onVerify}
          refreshReCaptcha={refreshReCaptcha}
        />
        <div className={style.inputValidIndicator}>
          <h3 className={style.step}>Шаг №7.</h3>
          {status === 'error' &&
            <ErrorText>Произошла ошибка при отправке формы. Попробуйте перезагрузить страницу или отправить форму позже снова</ErrorText>
          }
          <Button
            type='submit'
            theme={ButtonTheme.RED}
            className={style.buttonSubmit}
            disabled={isSending}
          >
            {
              isSending ?
                <>
                  Отправка
                  <Loader />
                </>
                :
                'Отправить'
            }
          </Button>
        </div>
      </form >
    </>
  )
}

export default LawyerQuestionForm;

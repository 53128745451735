import SkeletonPlaceholder from '../../UI/SkeletonPlaceholder/SkeletonPlaceholder';
import style from './Job.module.scss'

const JobSkeleton: React.FC = () => {
  return (
    <div className={style.categoriesList}>
      <div>
        <SkeletonPlaceholder className={style.titlePlaceholder} />
        <SkeletonPlaceholder className={style.textPlaceholder} />
        <SkeletonPlaceholder className={style.textPlaceholder} />
      </div>
      <div>
        <SkeletonPlaceholder className={style.titlePlaceholder} />
        <SkeletonPlaceholder className={style.textPlaceholder} />
        <SkeletonPlaceholder className={style.textPlaceholder} />
      </div>
    </div>
  )
}

export default JobSkeleton;
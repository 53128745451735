import { IReceptionFaqs } from '../../../../types/apiInterfaces'
import { useState, Fragment } from 'react'
import { Link } from 'react-router-dom'
import style from './Question.module.scss'
import { classNames } from '../../../../utils/classNames/classNames'

interface IQuestion {
  category: string,
  receptionFaqs: IReceptionFaqs[],
  slug: string
}

const Question: React.FC<IQuestion> = ({ category, receptionFaqs, slug }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  return (
    <li className={style.questionItem}>
      <h2 className={style.itemTitle} onClick={() => setIsOpen(pr => !pr)}>
        {category}
        <span className={classNames(style.caret, { [style.open]: isOpen })} />
      </h2>
      <div className={classNames(style.articles, { [style.open]: isOpen })}>
        <div style={{ minHeight: 0 }}>
          {receptionFaqs.slice(0, 2).map(({ answer, question }, index) => {
            return (
              <Fragment key={index}>
                <strong className={style.articleTitle}>{question}</strong>
                <div className={style.articleText} dangerouslySetInnerHTML={{ __html: answer }} />
              </Fragment>
            )
          })}
          <Link
            className={style.toAllArticles}
            to={slug}
          >
            Посмотреть все вопросы данного раздела
          </Link>
        </div>
      </div>
    </li>
  )
}

export default Question;
import { Swiper, SwiperSlide } from 'swiper/react'
import style from './FeedbackSlider.module.scss'
import { Navigation, Autoplay, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import { useRef, useState } from 'react'
import { formatPassedTime } from '../../../utils/formatPassedTime/formatPassedTime';
import FeedbackSliderItem from './FeedbackSliderItem/FeedbackSliderItem';
import { Swiper as SwiperType } from 'swiper/types';
import { useQuery } from 'react-query';
import { IReview } from '../../../types/apiInterfaces';
import { getData } from '../../../utils/services';
import FeedbackSliderSkeleton from './FeedbackSliderSkeleton/FeedbackSliderSkeleton';

export type AutoplayStatus = 'stop' | 'start'

const FeedbackSlider: React.FC = () => {
  const { data, isLoading } = useQuery({
    queryKey: ['review'],
    queryFn: (): Promise<IReview[]> => getData('review')
  })

  const navPrevRef = useRef<HTMLButtonElement | null>(null)
  const navNextRef = useRef<HTMLButtonElement | null>(null)
  const [, setSwiper] = useState<SwiperType>();

  if (isLoading) return <FeedbackSliderSkeleton />

  if (!data) return null

  return <div className={style.sliderWrapper}>
    <Swiper
      navigation={{
        prevEl: navPrevRef?.current,
        nextEl: navNextRef?.current,
      }}
      pagination={{
        clickable: true,
        bulletClass: style.customBullets,
        bulletActiveClass: style.activeCustomBullets,
        renderBullet: (_, className) => {
          return `<button class="${className}" style="margin-left: 20px"></button>`;
        }
      }}
      slidesPerView={1}
      loop={true}
      speed={500}
      spaceBetween={20}
      autoplay={{
        delay: 30000,
        disableOnInteraction: false,
      }}
      breakpoints={{
        768: {
          slidesPerView: 2
        }
      }}
      onSwiper={setSwiper}
      modules={[Autoplay, Navigation, Pagination]}
      className={style.slider}
    >
      {data?.map((item, index) => <SwiperSlide key={index}>
        <FeedbackSliderItem
          name={item.name}
          date={formatPassedTime(item.created_at)}
          text={item.text}
          title={item.title}
          slug={item.slug}
        />
      </SwiperSlide>)}
    </Swiper>

    <button className={style.navigationPrev} ref={navPrevRef} />
    <button className={style.navigationNext} ref={navNextRef} />
  </div>
}

export default FeedbackSlider;
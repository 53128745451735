import {useState, useCallback, useEffect} from 'react'
import { FormStatusType, IFooterEmail } from '../../../../types/formInterfaces';
import { GoogleReCaptcha } from 'react-google-recaptcha-v3';
import { sendFormEmail } from '../../../../utils/services';
import { useForm } from 'react-hook-form';
import Button from '../../../UI/Button/Button';
import Input from '../../../UI/Input/Input';
import ErrorText from '../../../UI/ErrorText/ErrorText';
import Loader from '../../../share/Loader/Loader';
import style from './FooterForm.module.scss'

const FooterForm: React.FC = () => {
  const [status, setStatus] = useState<FormStatusType>(null);
  const [isSending, setIsSending] = useState(false);

  const [token, setToken] = useState<string>();
  const [refreshReCaptcha, setRefreshReCaptcha] = useState<boolean>(false);
  const { register,
    handleSubmit,
    formState: { errors, touchedFields },
    reset
  } = useForm<IFooterEmail>({ mode: 'onBlur' });

  const onVerify = useCallback((token: string) => {
    setToken(token);
  }, []);

  useEffect(() => {
    const id = setInterval(() => {
      setRefreshReCaptcha(r => !r);
    }, 120000)
    return () => clearInterval(id)
  }, [])

  const onSubmit = async (data: IFooterEmail) => {
    try {
      setIsSending(true)
      setStatus(null)
      const response = await sendFormEmail(data.email, token ?? '');;
      if (!response.ok) {
        throw new Error();
      }
      setRefreshReCaptcha(r => !r);
      reset();
      setStatus('success');
    }
    catch (e) {
      setStatus('error')
    }
    finally {
      setIsSending(false)
    }
  }

  if (status === 'success') return <p style={{ marginTop: 15 }}>Вы подписались</p>

  return (
    <form className={style.form} onSubmit={handleSubmit(onSubmit)}>
      <Input
        id='subscribe_email'
        error={errors.email}
        touched={touchedFields.email}
        className={style.input}
        placeholder='E-mail'
        {...register('email', {
          required: true,
          pattern: /\S+@\S+\.\S+/
        })}
      />
      <GoogleReCaptcha
        action='homepage'
        onVerify={onVerify}
        refreshReCaptcha={refreshReCaptcha}
      />
      {status === "error" &&
        <ErrorText className={style.error}>
          Произошла ошибка при отправке формы. <br />
          Попробуйте перезагрузить страницу или <br />
          отправить форму позже снова
        </ErrorText>
      }
      <Button className={style.btnSubmit}
        type='submit'
        disabled={isSending}
      >
        {
          isSending ?
            <>
              <Loader />
            </>
            :
            'Подписаться'
        }
      </Button>
    </form>
  )
}

export default FooterForm;

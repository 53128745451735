import style from './Home.module.scss'
import HomeFirstBlock from "./HomeFirstBlock/HomeFirstBlock";
import HomeReviewBlock from "./HomeReviewBlock/HomeReviewBlock";
import HomeNewsBlock from "./HomeNewsBlock/HomeNewsBlock";
import { useEffect } from 'react';
import { setDocumentTitle } from '../../../utils/setDocumentTitle/setDocumentTitle';

const Home: React.FC = () => {
  useEffect(() => {
    setDocumentTitle('Общественная приемная С.В.Чижова')
  }, [])

  return (
    <main className={style.main}>
      <HomeFirstBlock />
      <HomeNewsBlock />
      <HomeReviewBlock />
    </main>
  )
}

export default Home;
import { ButtonHTMLAttributes } from 'react';
import style from './Button.module.scss'
import { classNames } from '../../../utils/classNames/classNames';

export enum ButtonTheme {
  PRIMARY = 'primary',
  RED = 'red'
}

export enum ButtonSize {
  S = 'size_s',
  M = 'size_m'
}

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children?: React.ReactNode
  theme?: ButtonTheme
  size?: ButtonSize
}

const Button: React.FC<ButtonProps> = (props) => {
  const {
    type = 'button',
    children,
    className,
    theme = ButtonTheme.PRIMARY,
    size = ButtonSize.M,
    ...otherProps
  } = props;

  const additionalClasses = [
    className ?? '',
    style[theme],
    style[size]
  ]

  return <button className={classNames(style.button, {}, additionalClasses)}
    type={type}
    {...otherProps}
  >
    {children}
  </button>
}

export default Button;
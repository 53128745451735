import { classNames } from '../../../utils/classNames/classNames'
import style from './Title.module.scss'

interface TitleProps {
  className?: string
  children: React.ReactNode
}

const Title: React.FC<TitleProps> = ({ children, className = '' }) => {
  return (
    <h1 className={classNames(style.title, {}, [className])}>{children}</h1>
  )
}

export default Title;
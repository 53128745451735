import SkeletonPlaceholder from '../../../UI/SkeletonPlaceholder/SkeletonPlaceholder';
import style from './FeedbackSliderSkeleton.module.scss'

const FeedbackSliderSkeleton: React.FC = () => {
  return <div className={style.container}>
    <SkeletonPlaceholder className={style.item} />
    <SkeletonPlaceholder className={style.item} />
  </div>
}

export default FeedbackSliderSkeleton;
import { useParams } from 'react-router-dom';
import style from './SingleReview.module.scss'
import { useEffect } from 'react'
import BreadCrumbs from '../../share/BreadCrumbs/BreadCrumbs';
import { setDocumentTitle } from '../../../utils/setDocumentTitle/setDocumentTitle';
import Container from '../../UI/Container/Container';
import Title from '../../UI/Title/Title';
import { getData } from '../../../utils/services';
import { useQuery } from 'react-query';
import { IMedia } from '../../../types/apiInterfaces';
import SinglePageSkeleton from '../../share/SinglePageSkeleton/SinglePageSkeleton';

const ReviewItem: React.FC = () => {
  const slug = useParams().id;

  const { data, isLoading } = useQuery({
    queryKey: ['single-media', slug],
    queryFn: (): Promise<IMedia> => getData(`media-about/get-news/${slug}`)
  })

  useEffect(() => {
    if (data) {
      setDocumentTitle(data.title)
    }
  }, [data])

  return <Container>
    <BreadCrumbs links={[{ name: 'Главная', link: '/' },
    { name: 'Отзывы', link: '/feedback' }, { name: '', link: '' }]} />
    {isLoading ? <SinglePageSkeleton /> :
      <div className={style.wrapper}>
        <Title>{data?.title}</Title>
        <div className={style.iframeWrapper}>
          <iframe src={data?.url}
            width="100%"
            title={data?.title}
            frameBorder={0}
            allow="clipboard-write; accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen />
        </div>
        <div className={style.description}
          dangerouslySetInnerHTML={{ __html: data?.text ?? '' }}
        />
      </div>}
  </Container>

}

export default ReviewItem;